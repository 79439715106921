import React from 'react';
import {graphql} from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import Link from 'gatsby-link';
import Img from 'gatsby-image';
//import PageTransition from 'gatsby-plugin-page-transitions';

//import Hero from '../../components/hero';
import ArticlePreviewEs from '../../components/article-preview_Es';
import SEO from '../../components/SEO';
import social_image from '../../assets/images/social-image.png';
import icon32 from '../../assets/images/favicon-32x32.png';
import Template from '../../components/layout';

class RootIndexEs extends React.Component {
  render () {
    const siteTitle =
      'Asesoría en Nutrición - blog por una Nutrióloga Psicoterapeuta';
    const posts = get (this, 'props.data.allContentfulBlogPost.edges');
    //const [author] = get(this, 'props.data.allContentfulPerson.edges');

    return (
      <Template location={get (this, 'props.location')}>
        <div
          style={{
            background: 'none repeat scroll 0 0 rgba(255, 255, 255, 0.7)',
          }}
        >
          <Helmet
            title={siteTitle}
            link={[
              {rel: 'shortcut icon', type: 'image/png', href: `${icon32}`},
              { rel: 'dns-prefetch', href: 'https://www.google.com' },
              { rel: 'dns-prefetch', href: 'https://stats.g.doubleclick.net' },
              { rel: 'dns-prefetch', href: 'https://www.google-analytics.com' },
              {
                rel: 'alternate',
                href: 'https://www.ilsevalle.com/en/nutrition-blog',
                hreflang: 'en',
              },
              {
                rel: 'alternate',
                href: 'https://www.ilsevalle.com/en/nutrition-blog',
                hreflang: 'x-default',
              },
            ]}
          >
            <html lang="es" />
          </Helmet>
          <SEO
            seodesc={
              'El blog de Ilse Valle Jones, una nutrióloga psicoterapeuta que puede ayudarte a mejorar tu alimentación y tener un estilo de vida saludable.'
            }
            socialImage={social_image}
            socialTitle={
              'Asesoría en Nutrición - La Bitacora de una Nutrióloga Psicoterapeuta'
            }
            twitter={'@ilsevallejones'}
          />
          <div className="wrapper">
            <Img
              sizes={
                this.props.data.blogHero.edges[0].node.childImageSharp.fluid
              }
              style={{
                maxHeight: '400px',
                height: '61.8vh',
                marginBottom: '1em',
              }}
              loading="eager"
              fadeIn={false}
            />
            <div className="container bloglist">
              <div className="row">
                <div className="span7 offset1">
                  {posts.map (({node}) => {
                    return (
                      <article
                        key={node.slug}
                        className="entry-header"
                        style={{paddingBottom: '3em'}}
                      >
                        <ArticlePreviewEs article={node} />
                      </article>
                    );
                  })}
                </div>
                <div className="span4">
                  <div className="widget-area">
                    <aside id="text-4" className="widget widget_text">
                      <h3 className="widget-title">Ilse Valle-Jones, MS</h3>
                      {' '}
                      <div className="textwidget">
                        <p>Nutrióloga Psicoterapeuta</p>
                        <Link to="/index_es#contact" style={{color: '#bc360a'}}>
                          Contacto
                        </Link>
                      </div>
                    </aside>
                    <aside id="text-3" className="widget widget_text">
                      {' '}
                      <div className="textwidget">
                        Soy una nutrióloga psicoterapeuta que puede ayudarte a mejorar tu alimentación y tener un estilo de vida saludable.
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Template>
    );
  }
}

export default RootIndexEs;

export const pageQueryEs2 = graphql`
query HomeQueryEs {
    allContentfulBlogPost(
        sort: { fields: [publishDate], order: DESC }
        filter: { lang: { eq: "es" } }
    ) {
        edges {
            node {
                title
                slug
                publishDate(formatString: "MMMM Do, YYYY")
                tags
                description {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }
    allContentfulPerson(filter: { id: { eq: "c15jwOBqpxqSAOy2eOO4S0m" } }) {
        edges {
            node {
                name
                shortBio {
                    shortBio
                }
                title
            }
        }
    }
    blogHero: allFile(filter: {relativePath: {regex: "/grapes.jpg/"}}) {
    edges {
      node {
        id
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
}
`;
