import React from 'react';
import Link from 'gatsby-link';
//import Img from 'gatsby-image';

import styles from './article-preview.module.css';

export default ({ article }) => (
  <div className={styles.preview}>
    <header className="entry-header">
    <h3 className={styles.previewTitle + " blogh1"} style={{margin: "0 0 .5em 0"}}>
        <Link to={`/blog/${article.slug}/`} style={{fontFamily:"Bitter, Georgia, serif", color:"#bc360a"}}>{article.title}</Link>
    </h3>
    </header>
        {/* <small>{article.publishDate}</small>*/}

    <div
      dangerouslySetInnerHTML={{
        __html: article.description.childMarkdownRemark.html,
      }}
    />
  </div>
)
